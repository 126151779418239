///
/// Faction by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Image */

	.image {
		border-radius: _size(border-radius);
		border: 0;
		display: inline-block;
		position: relative;

		img {
			border-radius: _size(border-radius);
			display: block;
		}

		&.left,
		&.right {
			max-width: 40%;

			img {
				width: 100%;
			}
		}

		&.left {
			float: left;
			margin: 0 1.5em 1em 0;
			top: 0.25em;
		}

		&.right {
			float: right;
			margin: 0 0 1em 1.5em;
			top: 0.25em;
		}

		&.fit {
			display: block;
			margin: 0 0 _size(element-margin) 0;
			width: 100%;

			img {
				width: 100%;
			}
		}

		&.main {
			display: block;
			margin: 0 0 (_size(element-margin) * 1.5) 0;
			width: 100%;

			img {
				width: 100%;
			}

			@include breakpoint(small) {
				margin: 0 0 _size(element-margin) 0;
			}
		}
	}

	a.image {
		overflow: hidden;

		img {
			@include vendor('transition', 'transform #{_duration(transition)} ease-in-out');
		}

		&:hover {
			img {
				@include vendor('transform', 'scale(1.075)');
			}
		}
	}