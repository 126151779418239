///
/// Faction by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Banner */

	#banner {
		@include color-typography(accent3);
		@include color-button(accent3);
		position: relative;
		height: 30em;
		cursor: default;

		> article {
			@include vendor('transition', 'opacity #{_duration(slider)}, visibility #{_duration(slider)}');
			@include vendor('display', 'flex');
			@include vendor('align-items', 'center');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 100%;
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			visibility: hidden;
			width: 100%;
			z-index: 0;

			&.visible {
				opacity: 1;
				visibility: visible;
			}

			&.top {
				z-index: 1;
			}

			&:before {
				@include vendor('transition', 'opacity #{_duration(slider)} ease-in-out');
				background-color: _palette(accent3, bg);
				content: '';
				height: 100%;
				left: 0;
				opacity: 0.33;
				position: absolute;
				top: 0;
				width: 100%;
			}

			> .inner {
				@include padding(2em, 4em);
				position: relative;
				width: 100%;
			}

			img {
				display: none;
			}

			h2 {
				color: inherit;
				font-size: 3.6em;
				margin-bottom: 0.25em;
			}
		}

		.indicators {
			bottom: 0.75em;
			list-style: none;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 1.5em;
			text-align: right;
			width: 100%;
			z-index: 2;

			li {
				cursor: pointer;
				display: inline-block;
				height: 1.25em;
				overflow: hidden;
				padding: 0;
				position: relative;
				text-indent: 1.25em;
				width: 1.25em;

				&:before {
					@include vendor('transform', 'translate(-50%, -50%)');
					@include vendor('transition', 'background-color #{_duration(transition)} ease-in-out');
					background-color: _palette(accent3, border-bg);
					border-radius: 100%;
					content: '';
					display: inline-block;
					height: 10px;
					left: 50%;
					position: absolute;
					text-indent: 0;
					top: 50%;
					width: 10px;
				}

				&.visible:before {
					background-color: _palette(accent3, fg-bold);
				}
			}
		}

		body.is-loading & {
			> article {
				&:before {
					opacity: 1;
				}
			}
		}

		@include breakpoint(large) {
			height: 28em;

			> article {
				h2 {
					font-size: 3em;
				}

				.inner {
					@include padding(2em, 4em);
				}
			}
		}

		@include breakpoint(medium) {
			text-align: center;
			height: 35em;

			.indicators {
				text-align: center;
				right: 0;
			}

			> article {
				.inner {
					@include padding(2em, 5em);
				}
			}
		}

		@include breakpoint(small) {
			height: 23em;

			> article {
				h2 {
					font-size: 2.5em;
				}

				.inner {
					@include padding(2em, 2em);
				}
			}
		}

		@include breakpoint(xsmall) {
			height: 28em;

			> article {
				h2 {
					font-size: 2em;
				}
			}
		}
	}