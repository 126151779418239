@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';
@import 'fontawesome4.min.css';
@import 'zapchance.css';
@import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600|Oswald:700';

/*
	Faction by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

	@include skel-breakpoints((
		xlarge: '(max-width: 1680px)',
		large: '(max-width: 1280px)',
		medium: '(max-width: 980px)',
		small: '(max-width: 736px)',
		xsmall: '(max-width: 480px)',
		xxsmall: '(max-width: 360px)'
	));

	@include skel-layout((
		reset: 'full',
		boxModel: 'border',
		grid: ( gutters: 2em ),
		breakpoints: (
			large: (
				grid: ( gutters: 1.5em )
			),
			small: (
				grid: ( gutters: 1.25em )
			)
		)
	));

	@mixin color($p) {
		@include color-typography($p);
		@include color-box($p);
		@include color-button($p);
		@include color-form($p);
		@include color-list($p);
		@include color-section($p);
		@include color-table($p);
		@include color-sidebar($p);
		@include color-main($p);
		@include color-features($p);
		@include color-spotlights($p);
		@include color-featured-posts($p);
		@include color-posts($p);
	}

	@mixin sr-only {
		border: 0;
		clip: rect(0,0,0,0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}

// Base.

	@import 'base/page';
	@import 'base/typography';

// Component.

	@import 'components/box';
	@import 'components/button';
	@import 'components/form';
	@import 'components/icon';
	@import 'components/image';
	@import 'components/list';
	@import 'components/section';
	@import 'components/table';
	@import 'components/sidebar';
	@import 'components/main';
	@import 'components/features';
	@import 'components/spotlights';
	@import 'components/featured-posts';
	@import 'components/posts';
	@import 'components/wrapper';

// Layout.

	@import 'layout/header';
	@import 'layout/navPanel';
	@import 'layout/banner';
	@import 'layout/footer';
	
    .logo {

                        h2 {
                                font-family: ('Conv_zapchancenormal');
                                font-size: 2.5em;
                                letter-spacing: 0.225em;
                                margin: 0 0.25em 0.25em 0;
                                padding: 0;
                                text-transform: uppercase;
                                vertical-align: middle;
                        }

        }
