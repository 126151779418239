///
/// Faction by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* List */

	ol {
		list-style: decimal;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1.25em;

		li {
			padding-left: 0.25em;
		}
	}

	ul {
		list-style: disc;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1em;

		li {
			padding-left: 0.5em;
		}

		&.alt {
			list-style: none;
			padding-left: 0;

			li {
				border-top: solid _size(border-width);
				padding: 0.5em 0;

				&:first-child {
					border-top: 0;
					padding-top: 0;
				}
			}
		}

		&.icons {
			cursor: default;
			list-style: none;
			padding-left: 0;

			li {
				display: inline-block;
				padding: 0 1em 0 0;

				&:last-child {
					padding-right: 0;
				}

				.icon {
					&:before {
						font-size: 2em;
					}
				}
			}
		}

		&.actions {
			cursor: default;
			list-style: none;
			padding-left: 0;

			li {
				display: inline-block;
				padding: 0 (_size(element-margin) * 0.5) 0 0;
				vertical-align: middle;

				&:last-child {
					padding-right: 0;
				}
			}

			&.small {
				li {
					padding: 0 (_size(element-margin) * 0.25) 0 0;
				}
			}

			&.vertical {
				li {
					display: block;
					padding: (_size(element-margin) * 0.5) 0 0 0;

					&:first-child {
						padding-top: 0;
					}

					> * {
						margin-bottom: 0;
					}
				}

				&.small {
					li {
						padding: (_size(element-margin) * 0.25) 0 0 0;

						&:first-child {
							padding-top: 0;
						}
					}
				}
			}

			&.fit {
				display: table;
				margin-left: (_size(element-margin) * -0.5);
				padding: 0;
				table-layout: fixed;
				width: calc(100% + #{(_size(element-margin) * 0.5)});

				li {
					display: table-cell;
					padding: 0 0 0 (_size(element-margin) * 0.5);

					> * {
						margin-bottom: 0;
					}
				}

				&.small {
					margin-left: (_size(element-margin) * -0.25);
					width: calc(100% + #{(_size(element-margin) * 0.25)});

					li {
						padding: 0 0 0 (_size(element-margin) * 0.25);
					}
				}
			}

			@include breakpoint(xsmall) {
				margin: 0 0 _size(element-margin) 0;

				li {
					padding: (_size(element-margin) * 0.5) 0 0 0;
					display: block;
					text-align: center;
					width: 100%;

					&:first-child {
						padding-top: 0;
					}

					> * {
						width: 100%;
						margin: 0 !important;

						&.icon {
							&:before {
								margin-left: -2em;
							}
						}
					}
				}

				&.small {
					li {
						padding: (_size(element-margin) * 0.25) 0 0 0;

						&:first-child {
							padding-top: 0;
						}
					}
				}
			}
		}

		&.contact-icons {
			list-style-type: none;
			padding: 0;

			li {
				min-height: 2.4em;
				padding-left: 3.5em;
				margin-bottom: 1.5em;

				&:before {
					border-radius: 100%;
					border: solid _size(border-width);
					font-size: 1em;
					height: 2.4em;
					left: 0;
					line-height: 2.4em;
					position: absolute;
					text-align: center;
					top: -0.25em;
					width: 2.4em;
				}

				a {
					border-bottom: 0;
					color: inherit;
				}
			}
		}

		&.pagination {
			cursor: default;
			list-style: none;
			padding-left: 0;

			li {
				display: inline-block;
				padding-left: 0;
				vertical-align: middle;

				> .page {
					@include vendor('transition', (
						'background-color #{_duration(transition)} ease-in-out',
						'color #{_duration(transition)} ease-in-out'
					));
					border-bottom: 0;
					border-radius: 1em;
					display: inline-block;
					height: 1.5em;
					line-height: 1.325em;
					margin: 0 0.125em;
					min-width: 1.5em;
					padding: 0 0.5em;
					text-align: center;
				}

				&:first-child {
					padding-right: 0.75em;
				}

				&:last-child {
					padding-left: 0.75em;
				}
			}

			@include breakpoint(xsmall) {
				@include vendor('display', 'flex');

				li {
					&:nth-child(n+2):nth-last-child(n+2) {
						display: none;
					}

					&:first-child {
						padding-right: 0.375em;
					}

					&:last-child {
						padding-left: 0.375em;
					}
				}

				li {
					width: 50%;

					.button {
						width: 100%;
					}
				}
			}
		}
	}

	dl {
		margin: 0 0 _size(element-margin) 0;

		dt {
			display: block;
			font-weight: _font(weight-bold);
			margin: 0 0 (_size(element-margin) * 0.5) 0;
		}

		dd {
			margin-left: _size(element-margin);
		}
	}

	@mixin color-list($p: null) {
		$highlight: _palette($p, highlight);

		ul {
			&.alt {
				li {
					border-top-color: _palette($p, border);
				}
			}

			&.pagination {
				li {
					> .page {
						color: _palette($p, fg);

						&.active {
							background-color: _palette($highlight, bg);
							color: _palette($highlight, fg-bold) !important;

							&:hover {
								background-color: lighten(_palette($highlight, bg), 5);
							}

							&:active {
								background-color: darken(_palette($highlight, bg), 5);
							}
						}
					}
				}
			}
		}
	}

	@include color-list;